export const configConstants = {
  API_URL: window.location.protocol + "//" + window.location.hostname,
  //API_URL: process.env.REACT_APP_API_URL,
  PREFIX_URL: "/bli",
  GOOGLE_SITE_KEY: process.env.REACT_APP_SITE_KEY,
  headers: {
    "X-IBM-Client-Secret": process.env.REACT_APP_IBM_Client_Secret,
    "X-IBM-Client-Id": process.env.REACT_APP_IBM_Client_ID,
  },
  version: "2.3.7"
};
